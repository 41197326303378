
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { NavigationGuardNext, Route } from 'vue-router';
import MonthlyReport from '@/components/MonthlyReport/MonthlyReport.vue';
import MonthlyReportRegister from '@/views/MonthReport/MonthlyReportRegister.vue';
import MonthReportInfoClass from '@/model/monthReport/MonthReportInfoClass';
import MonthReportApi from '@/module/api/MonthReportApi';
import TypeJpDate from '@/model/type/TypeJpDate';
import UserBaseInfo from '@/model/user/UserBaseInfo';
import BackButton from '@/components/Parts/BackButton.vue';
import UserApi from '@/module/api/UserApi';
import UserInfo from '@/model/user/UserInfo';

Component.registerHooks([
  'beforeRouteEnter'
]);
@Component({
  components: {
    MonthlyReport,
    MonthlyReportRegister,
    BackButton
  }
})
export default class MonthlyReportList extends Vue {
  @State('loginUser') loginUser!: UserBaseInfo;
  userId: string = '';
  targetUser: UserBaseInfo = new UserBaseInfo();
  show = false;
  showDialog = false;
  newReport = false;
  monthReportInfoList: MonthReportInfoClass[] = [];
  reportData:MonthReportInfoClass = new MonthReportInfoClass();
  hashId: number = -1;

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<MonthlyReportList>): void {
    next((vm: MonthlyReportList) => vm.initialize(to));
  }
  initialize(to: Route): void {
    this.userId = to.params.userId;

    // ハッシュでmonthreportIdが指定されている場合
    let { hash } = window.location;
    if (hash) {
      this.hashId = parseInt(hash.split('-')[1], 10);
    } else {
      hash = '';
    }
    if (this.userId !== undefined && !this.loginUser.priv.isAdminGroup) {
      this.$router.push(`/monthlyReportList${hash}`);
    }
  }

  scroll(): void {
    // 指定位置へスクロールさせる
    const { hash } = window.location;
    if (hash) {
      setTimeout(() => {
        window.location.href = hash;
      }, 100);
    }
  }

  /**
   * 初期表示処理
   */
  mounted():void {
    this.getData(true);
  }

  /**
   * 報告を追加ボタンを押下
   */
  add(): void {
    this.reportData = new MonthReportInfoClass();
    this.reportData.date = TypeJpDate.getToday();
    this.reportData.date.day = 1;
    this.showDialog = true;
    this.newReport = true;
  }

  /**
   * 削除処理後
   */
  deleted() :void {
    this.getData(false);
  }

  /**
   * 登録処理後
   */
  saved() :void {
    this.showDialog = false;
    this.getData(false);
  }

  /**
   * 更新・複製登録語
   */
  research() :void {
    this.getData(false);
  }

  /**
   * 一覧データ取得
   */
  getData(initial: boolean) :void {
    if (this.userId !== undefined) {
      UserApi.privfindByID(this.userId)
        .then((res) => {
          this.targetUser = UserInfo.clone(res.data).userBaseInfo;
        });
    } else {
      this.targetUser = this.loginUser;
    }

    // 一般ユーザ又はパラメータがない場合
    if (this.userId === undefined || !this.loginUser.priv.isAdminGroup) {
      MonthReportApi.findAll()
        .then((res) => {
          this.monthReportInfoList = MonthReportInfoClass.cloneList(res.data);
          if (initial) this.scroll();
        });
    // 管理者の場合
    } else if (this.loginUser.priv.isAdminGroup) {
      MonthReportApi.findAllByPrivUser(this.userId)
        .then((res) => {
          this.monthReportInfoList = MonthReportInfoClass.cloneList(res.data);
          if (initial) this.scroll();
        });
    }
  }
}
