/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import { Position, PositionResult } from 'vue-router/types/router';
import UserApi from '@/module/api/UserApi';
import store from '../store/index';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    // 送受信一覧
    path: '/',
    name: 'Home',
    meta: { requiredAuth: true },
    component: () => import('@/views/post/PostsViewer.vue'),
  },
  {
    // LINE連携認証画面
    path: '/linelink',
    name: 'LineLink',
    component: () => import('@/views/sns/LineLink.vue'),
  },
  {
    // ログイン
    path: '/login.html',
    name: 'Login',
    component: () => import('@/views/Account/Login.vue'),
  },
  {
    // ユーザー登録
    path: '/signup',
    name: 'SignUp',
    component: () => import('@/views/Account/SignUp.vue'),
  },
  {
    // LINE連携
    path: '/line',
    name: 'LineConnect',
    meta: { requiredAuth: true },
    component: () => import('@/views/sns/LineConnect.vue'),
  },
  {
    // ユーザー情報編集
    path: '/userprofileEdit/:userId?',
    name: 'UserProfileEdit',
    meta: { requiredAuth: true },
    component: () => import('@/views/User/UserProfileEdit.vue'),
  },
  {
    // パスワード変更
    path: '/passwordChange',
    name: 'PasswordChange',
    meta: { requiredAuth: true },
    component: () => import('@/views/Account/PasswordChange.vue'),
  },
  {
    // パスワード再設定画面(メール送信)
    path: '/passwordForget',
    name: 'PasswordForget',
    component: () => import('@/views/Account/PasswordForget.vue'),
  },
  {
    // パスワード再設定画面
    path: '/passwordReset/:accessKey/:userId',
    name: 'PasswordReset',
    component: () => import('@/views/Account/PasswordReset.vue'),
  },
  {
    // 月末報告登録画面
    path: '/monthlyReportList/:userId?',
    name: 'MonthlyReportList',
    meta: { requiredAuth: true, requiredApprove: true },
    component: () => import('@/views/MonthReport/MonthlyReportList.vue'),

  },
  {
    // 資格登録（ユーザー編集から遷移）
    path: '/licenseRegister/:userId?',
    name: 'LicenseRegister',
    meta: { requiredAuth: true },
    component: () => import('@/views/User/LicenseRegister.vue'),

  },
  {
    // 経歴登録（ユーザー編集から遷移）
    path: '/historyRegister/:userId?',
    name: 'HistoryRegister',
    meta: { requiredAuth: true },
    component: () => import('@/views/User/HistoryRegister.vue'),

  },
  {
    // 情報投稿
    // ID指定で編集
    path: '/informationpost/:postId?',
    name: 'InformationPost',
    meta: { requiredAuth: true, requiredApprove: true },
    component: () => import('@/views/post/InformationPost.vue'),
  },
  {
    // 投稿詳細
    path: '/information/:postId',
    name: 'Information',
    meta: { requiredAuth: true, requiredApprove: true },
    component: () => import('@/views/post/Information.vue'),

  },
  {
    // 配信先選択
    path: '/postToSelector',
    name: 'PostToSelector',
    meta: { requiredAuth: true, requiredAdmin: true, requiredApprove: true },
    component: () => import('@/views/post/PostViewerSelector.vue'),

  },
  {
    // 送信参照
    path: '/informationList',
    name: 'InformationList',
    meta: { requiredAuth: true, requiredApprove: true },
    component: () => import('@/views/post/MyPostList.vue'),

  },
  {
    // 技術者一覧
    path: '/engineerList',
    name: 'EngineerList',
    meta: { requiredAuth: true, requiredAdmin: true, requiredApprove: true },
    component: () => import('@/views/User/EngineerList.vue'),

  },
  {
    // 既読者一覧（閲覧状況）
    path: '/readedUsers/:postId/:hasLimit',
    name: 'ReadedUserList',
    meta: { requiredAuth: true, requiredApprove: true },
    component: () => import('@/views/User/ReadedUserList.vue'),

  },
  {
    // ユーザー情報参照
    path: '/userProfile/:userId?',
    name: 'UserProfile',
    meta: { requiredAuth: true },
    component: () => import('@/views/User/UserProfile.vue'),

  },
  {
    // 書庫
    path: '/library/:class',
    name: 'Library',
    meta: { requiredAuth: true, requiredApprove: true },
    component: () => import('@/views/Library/Library.vue'),

  },
  {
    // 書庫アップロード(管理者)
    path: '/libraryUpload/:fileId?/:category?',
    name: 'LibraryUpload',
    meta: { requiredAuth: true, requiredAdmin: true, requiredApprove: true },
    component: () => import('@/views/Library/LibraryUpload.vue'),

  },
  {
    // 勤務先
    path: '/workplace',
    name: 'Workplace',
    meta: { requiredAuth: true, requiredAdmin: true, requiredApprove: true },
    component: () => import('@/views/Workplace/Workplace.vue'),

  },
  {
    // 勤務先一覧
    path: '/workplaceList/:userId?',
    name: 'WorkplaceList',
    meta: { requiredAuth: true, requiredApprove: true },
    component: () => import('@/views/Workplace/WorkplaceList.vue'),

  },
  {
    // 休暇申請
    path: '/vacationRequest/:postId?',
    name: 'VacationRequest',
    meta: { requiredAuth: true, requiredApprove: true },
    component: () => import('@/views/Vacation/VacationRequest.vue'),

  },
  {
    // 休暇申請参照
    path: '/vacationRequestViewer/:postId?',
    name: 'VacationRequestViewer',
    meta: { requiredAuth: true, requiredApprove: true },
    component: () => import('@/views/Vacation/VacationRequestViewer.vue'),

  },
  {
    // 本登録画面
    path: '/activate/:accessKey/:userId',
    name: 'Register',
    component: () => import('@/views/Account/Register.vue'),

  }
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function scrollBehavior(to: Route, from: Route, savedPosition: Position | void): PositionResult | Promise<any> {
  if (savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        // PositionResult型で返したいところだが
        // ここでselectorを省略しないと正しい指定にならないので戻り値をPromise<any>にする
        // (selector を string にしないとPositionResultにならない)
        resolve({ selector: undefined, offset: savedPosition, behavior: 'smooth' });
      }, 300);
    });
  }
  return { x: 0, y: 0 };
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
});

/**
 * ログイン済み判定
 */
function isLogin(): boolean {
  // Cookieの有無でログイン済みを判断する（SESSIONはhttpOnlyなので参照不可のため代替Cookieで判断）
  const hoge = document.cookie?.split('; ');
  if (!hoge) return false;
  const finded = hoge.find((row) => row.startsWith('PERSON_LOGIN_TOKEN'));
  if (!finded) return false;
  return finded.length > 0;
}

/**
 * ログイン情報が未取得なら取るまで待つ
 */
async function loginInfoWait() {
  if (!store.getters.isLoaded) {
    const res = await UserApi.getLoginUser();
    await store.commit('commitUserInfo', res.data);
  }
}

/**
 * 権限判定
 */
async function isAdmin(): Promise<boolean> {
  await loginInfoWait();
  return store.getters.isAdminGroup;
}
/**
 * 承認済みユーザーか判定
 */
async function isActiveUser(): Promise<boolean> {
  await loginInfoWait();
  return store.getters.isActiveUser;
}

/**
 * 画面最上部へスクロール
 */
function scrollToTop(): void {
  // 初回の画面遷移の場合、すぐにscrollするとVueRouterの座標位置保存より前に先頭に戻してしまうため
  // 少し時間をずらしてTopに移動させる
  setTimeout(() => {
    window.scroll({ top: 0, behavior: 'auto' });
  }, 50);
}

/**
 * ルーティングガード
 * ログイン有無で動作を変える
 */
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    await loginInfoWait();
    if (!isLogin()) {
      // 未ログインならログイン画面に飛ばす
      next('/login.html');
      scrollToTop();
      return;
    }
    if (to.matched.some((record) => record.meta.requiredAdmin)) {
      const res = await isAdmin();
      if (!res) {
        // 管理者でない前の画面に飛ばす
        router.go(-1);
        scrollToTop();
        return;
      }
    }
    if (to.matched.some((record) => record.meta.requiredApprove)) {
      const res = await isActiveUser();
      if (!res) {
        // 未承認ユーザーが不正な画面にアクセスしたらTOPに飛ばす
        next('/');
        scrollToTop();
        return;
      }
    }
    next();
    scrollToTop();
    return;
  }
  if (isLogin()) {
    // 認証不要画面にアクセスしてログイン済みなら初期画面に飛ばす
    next('/');
    scrollToTop();
    return;
  }
  next();
  scrollToTop();
});

export default router;
